<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="about">
            <div class="abt1">CONTACT US</div>
            <div class="abt2">联系我们</div>
        </div>
        <div class="detail">
            <div class="left">
                <div class="item">
                    <img src="@/assets/images/Group901.png" alt="" />
                    <div class="content">联系电话：{{ data.phone }}</div>
                </div>
                <div class="item">
                    <img src="@/assets/images/Group297.png" alt="" />
                    <div class="content">邮箱地址：{{ data.email }}</div>
                </div>
                <div class="item">
                    <img src="@/assets/images/Group295.png" alt="" />
                    <div class="content">场馆地址：{{ data.address }}</div>
                </div>
                <div class="item">
                    <img src="@/assets/images/Group902.png" alt="" />
                    <div class="content">开放时间：{{ data.sundayStartTime }} - {{ data.sundayEndTime }}</div>
                </div>
            </div>
            <!-- <div class="right">
                <div class="item">
                    <img src="@/assets/images/Frame-54.png" alt="" />
                    <span>微信公众号</span>
                </div>
                <div class="item">
                    <img src="@/assets/images/Frame-54.png" alt="" />
                    <span>微信公众号</span>
                </div>
            </div> -->
        </div>
        <div class="luxian">
            <span>公交路线：{{ data.trafficRoute }}</span>
            <!--            <span>地铁路线：1，2号线可直达</span>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'contact',
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '关于本馆',
                },
                {
                    title: '联系我们',
                },
            ],
            data: {},
        };
    },
    created() {
        this.$hao.getLibrarysDetail(localStorage.getItem('id')).then((res) => {
            this.data = res.data;
        });
    },
};
</script>

<style scoped lang="less">
.about {
    display: flex;
    flex-direction: column;

    .abt1 {
        font-weight: 500;
        font-size: 46px;
        line-height: 67px;
        color: #ed6c00;
    }

    .abt2 {
        font-size: 24px;
        line-height: 35px;
        color: #ed6c00;
    }
}

.detail {
    display: flex;
    margin-top: 24px;
    padding: 12px 24px 24px 24px;
    background: white;
    align-items: center;
    justify-content: space-between;

    .left {
        .item {
            display: flex;
            align-items: center;
            margin-top: 12px;

            img {
                width: 32px;
                height: 32px;
                margin-right: 6px;
            }

            .content {
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
    }

    .right {
        display: flex;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 16px;
            img {
                width: 96px;
                height: 96px;
            }

            span {
                margin-top: 8px;
                font-size: 13px;
                line-height: 19px;
                text-align: center;
                color: #666666;
            }
        }
    }
}

.luxian {
    padding: 24px;
    background: white;
    border: 1px solid #ebebeb;
    span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        &:first-child {
            margin-bottom: 12px;
        }
    }
}
</style>
